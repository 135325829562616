import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import UserTabView from "./user/UserTabView";
import ProductTabView from "./product/ProductTabView";
import CourierTabView from "../couriers/CourierTabView";
import Setting from "./setting";
import CommercialTaxTabView from "./user/CommercialTaxTabView";

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function TabView({ tabIndex, setTabIndex }) {
    const theme = useTheme();

    return (
        <Box>
            {/* <TabPanel
                value={tabIndex}
                index={0}
                dir={theme.direction}
            >
                <ProductTabView setTabIndex={setTabIndex} />
            </TabPanel> */}
            <TabPanel
                value={tabIndex}
                index={0}
                dir={theme.direction}
            >
                <CommercialTaxTabView setTabIndex={setTabIndex} />
            </TabPanel>
            {/* <TabPanel
                value={tabIndex}
                index={1}
                dir={theme.direction}
            >
                <CourierTabView setTabIndex={setTabIndex} />
            </TabPanel>
            <TabPanel
                value={tabIndex}
                index={2}
                dir={theme.direction}
            >
                <Setting setTabIndex={setTabIndex} />
            </TabPanel> */}
        </Box>
    );
}

export default TabView;
